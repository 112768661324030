<script lang="ts">
export default { name: 'PageResolver' };
</script>

<script lang="ts" setup>
import { Ref, resolveComponent } from 'vue';
import { pascalCase } from 'scule';
import {
  useNavigationContext,
  useNavigationSearch,
} from '@shopware-pwa/composables-next';
import { SeoUrl } from '@shopware-pwa/types';

const { clearBreadcrumbs } = useBreadcrumbs();

const NOT_FOUND_COMPONENT = 'errors/RoutingNotFound';
const { resolvePath } = useNavigationSearch();
const route = useRoute();

// TODO: @Björn bitte mal slide(3) hier checken. Das kann ja eig nicht die Lösung sein,
//die Sprache wegzunehmen /de oder /en werden entfernt
const { data: seoResult } = await useAsyncData(
  'cmsResponse' + route.path,
  async () => {
    var path = route.path.slice(3);
    if (route.query?.number) {
      path += '?number=' + route.query.number;
    }

    return await resolvePath(path);
  }
);

onBeforeRouteLeave(() => {
  clearBreadcrumbs();
});

const { routeName, foreignKey } = useNavigationContext(
  seoResult as Ref<SeoUrl>
);

function render() {
  const componentName = routeName.value;
  if (!componentName)
    return h('div', h(resolveComponent(pascalCase(NOT_FOUND_COMPONENT))));

  const componentNameToResolve = pascalCase(componentName as string);
  const cmsPageView = routeName && resolveComponent(componentNameToResolve);
  if (cmsPageView) {
    if (cmsPageView === componentNameToResolve)
      return h('div', {}, 'Problem resolving component: ' + componentName);
    return h('div', h(cmsPageView, { navigationId: foreignKey.value }));
  }
  return h('div', {}, 'Loading...');
}
</script>

<template>
  <render />
</template>
